<template>
  <div>
    <DataTable
      v-model:filters="rowFilters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <template v-if="hasHeader" #header>
        <slot name="header"></slot>
      </template>
      <slot name="columns"></slot>
      <template #expansion="details">
        <DataTable
          :value="details.data.details"
          :scrollable="true"
          class="p-datatable-sm"
          showGridlines
        >
          <template #empty>
            <div class="text-center">Tidak ada data.</div>
          </template>
          <template #loading>
            <div class="text-center">Memuat data, harap tunggu...</div>
          </template>
          <Column
            field="nama_simpanan"
            header="nama simpanan"
            style="min-width: 15rem; background-color: #f8f9fa"
          />
          <Column
            field="jumlah"
            header="jumlah simpanan"
            style="min-width: 10rem; justify-content: flex-end"
          >
            <template #body="slotProps">
              <span style="text-align: right">
                {{ formatCurrency(slotProps.data.jumlah) }}
              </span>
            </template>
          </Column>
          <template #footer>
            <table class="flex justify-content-end">
              <tr>
                <td width="100%">
                  <table class="justify-content-end text-700">
                    <tr>
                      <td>GRAND TOTAL</td>
                      <td class="text-right">
                        {{ formatCurrency(details.data.total || 0) }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </template>
        </DataTable>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/index'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    hasHeader: {
      type: Boolean,
      defaut: false,
    },
  },
  data() {
    return {
      expandedRows: [],
      rowFilters: this.filters,
      options: this.gridOptions,
    }
  },

  methods: {
    initFilters() {
      this.rowFilters = this.$props.filters
      this.onFilter()
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.rowFilters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.rowFilters
      this.options.page = 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
  },
}
</script>
